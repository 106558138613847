/* Header.css */

.header-container {
  background-color: #394773;
  border-radius: 25px;
  text-align: center;
  width: 80%;
  padding: 20px 0;
  margin: auto;
  box-shadow: 0 4px 6px rgba(169, 152, 225, 0.872);
}

.header-container h1 {
  color: #c2c9d8;
  text-align: center;
  font-family: "Edu AU VIC WA NT Hand", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;  
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
}

.header-container img {
  width: 20%;
  margin: 0 auto;
  display: block;
}