/* .marketplace-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgb(22, 32, 32);
} */

.marketplace-container{
    width: 100%;
  }
  
  .marketplace-container .main-content{
    padding: 20px;
    background-color: #394773;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    width: 80%;
    margin: auto;
  }
  
  .top-marketplace-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .marketplace-container .top-marketplace-container {
    text-align: center;
    margin-bottom: 20px;
    background-color: rgb(2, 1, 16);
    border-radius: 8px;
    box-shadow: 0 0 30px rgba(15, 6, 30, 0.797);
  }
  
  .marketplace-container .top-marketplace-container h1 {
    color: #a7abae;
    font-size: 2em;
    font-family: monospace;
    font-variant: small-caps;
  }
  
  .marketplace-container .top-marketplace-container p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: hsl(210, 10%, 88%);
    line-height: 1.25;
    margin-top: 10px;
    padding: 10px;
  }
  
  .marketplace-container section {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;/* .marketplace-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgb(22, 32, 32);
  } */
  
  .marketplace-container{
    width: 100%;
  }
  
  .marketplace-container .main-content{
    padding: 20px;
    background-color: #394773;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    width: 80%;
    margin: auto;
  }
  
  .top-marketplace-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .marketplace-container .top-marketplace-container {
    text-align: center;
    margin-bottom: 20px;
    background-color: rgb(2, 1, 16);
    border-radius: 8px;
    box-shadow: 0 0 30px rgba(15, 6, 30, 0.797);
  }
  
  .marketplace-container .top-marketplace-container h1 {
    color: #a7abae;
    font-size: 2em;
    font-family: monospace;
    font-variant: small-caps;
  }
  
  .marketplace-container .top-marketplace-container p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: hsl(210, 10%, 88%);
    line-height: 1.25;
    margin-top: 10px;
    padding: 10px;
  }
  
  .marketplace-container section {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .marketplace-container section h2 {
    color: #308c98;
    font-size: 1.5em;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;  padding: 5px;
  }
  
  .marketplace-container section p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    line-height: 2;
    margin-top: 10px;
    padding: 10px;
  }
  
  .marketplace-container section p::first-letter {
    font-size: 1.25em;
    font-weight: bold;
  }
  
  .marketplace-container footer {
    text-align: center;
    margin-top: 20px;
  }
  }
  
  .marketplace-container section h2 {
    color: #308c98;
    font-size: 1.5em;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;  padding: 5px;
  }
  
  .marketplace-container section p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    line-height: 2;
    margin-top: 10px;
    padding: 10px;
  }
  
  .marketplace-container section p::first-letter {
    font-size: 1.25em;
    font-weight: bold;
  }
  
  .marketplace-container footer {
    text-align: center;
    margin-top: 20px;
  }